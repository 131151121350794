import * as React from 'react'
import { Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import './PrivacyPolicy.scss'

interface StateShape {}
interface PropsShape {}

export default class PrivacyPolicy extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        return (
            <div className="PrivacyPolicy">
                <Link to="https://intellyq.com" className="intellyq-logo-link">
                    <img
                        className="intellyq-logo"
                        src="/logo-main.png"
                        alt="IntellyQ Logo"
                    />
                </Link>
                <Container className="content" text={true}>
                    <h3>Privacy Policy</h3>
                    <p>
                        Tranquil Technologies FZ-LLC (“we”/”us”) is committed to
                        protecting your privacy. We make the website,
                        https://www.intellyq.com (the “Website”), available
                        together with our services as further described in our
                        Terms of Use (the “Services”). This Privacy Policy
                        describes how we collect, store, use, and distribute
                        information about our users through the Website and
                        Services.
                    </p>
                    <p>
                        This section outlines how personal information may be
                        collected and used (or not used) on this Website and
                        outlines responsibilities and liabilities that
                        correspond to such collection and/or use. These terms
                        cover this Website and its content exclusively, and do
                        not include Websites that are linked or malicious third
                        parties. It is our commitment to end users to operate
                        this Website with integrity and honesty—taking the time,
                        effort, and care to protect users and information that
                        may be provided.
                    </p>

                    <h4>Consent</h4>
                    <p>
                        By using the Website or our Services, you consent to the
                        use of your Personal Information as described in this
                        Privacy Policy. Except as set forth in this Privacy
                        Policy, your Personal Information will not be used for
                        any other purpose without your consent. We will not
                        actively collect Personal Information for the purpose of
                        sale or marketing in a way that specifically identifies
                        the individual. In other words, we don’t sell customer
                        lists. You may withdraw your consent to our processing
                        of your Personal Information at any time. However,
                        withdrawing consent may result in your inability to
                        continue using the Website and/or the Services.
                    </p>

                    <h4>Information Collection</h4>
                    <p>
                        We aim to collect, use, and disclose only such
                        information as is required to enable us to manage your
                        account, to provide the Services, to maintain our
                        customer/visitor lists, to respond to your inquiries or
                        provide feedback, for identification and authentication
                        purposes, and for service improvement. We will maintain
                        the confidentiality of any contact information you
                        provide to us on signing up for our Services or
                        contacting us with questions or for further information
                        and we will use it only for the purposes for which we
                        have collected it (subject to the exclusions and
                        disclosures we have listed below), unless you agree that
                        we may disclose it to other third parties.
                    </p>
                    <p>
                        Two types of information may be collected through the
                        Website and our Services: Personal Information and
                        Non-Personal Information. This Privacy Policy does not
                        extend to the collection, use, or disclosure of the
                        following information which is currently not limited by
                        applicable privacy laws: (a) information that is
                        publicly available, such as names, addresses, telephone
                        numbers, and electronic addresses when listed in a
                        directory or made available through directory
                        assistance; or (b) Non-Personal Information (as defined
                        further below).
                    </p>
                    <p>
                        "Personal Information" is personally identifiable
                        information, such as your name, address, email address,
                        credit card information, birth date, and gender. At the
                        time of collection, we will clearly identify the
                        information being collected and the purposes for which
                        it will be used. It is always your choice whether or not
                        to provide Personal Information, but if you choose not
                        to provide certain requested Personal Information, in
                        some instances, you may not be able to register to use
                        the Website or be able to access and use the Website at
                        all. In other instances, your choice not to provide
                        certain other Personal Information may mean that you
                        will not be able to use certain features of the Website.
                        We may collect Personal Information in respect of the
                        Website through registration processes, communications
                        with you, information downloads, service use, user
                        support, and surveys.
                    </p>
                    <p>
                        "Non-Personal Information" is information of an
                        anonymous nature, such as an Internet Protocol Address
                        (IP Address), the domain used to access the Website, and
                        the type and version of browser or operating system
                        being used by visitors to the Website. Aggregate
                        information, such as demographic statistics of our users
                        (e.g. average age or geographical allocation of our
                        users), number of visitors, what pages users access or
                        visit, and average time spent on the Website is not
                        considered Personal Information. Similarly, business
                        contact information such as the name, title, business
                        address, or telephone number of a business or
                        professional person or an employee of an organization is
                        not considered Personal Information.
                    </p>
                    <p>
                        Although the use of certain Non-Personal Information
                        collected, used, or disclosed through the Internet as
                        described herein is not restricted (and to the extent
                        that such is the case, the obligations under this
                        Privacy Policy do not apply to such information), we
                        provide information in this Privacy Policy about the
                        collection of such information for the sake of
                        transparency with respect to the operation of the
                        Website. Such Non-Personal Information is collected or
                        derived by us in the course of operating this Website.
                        For example, our web servers may automatically collect
                        Non-Personal Information that is provided through your
                        browser or stored in a cookie when you choose to visit
                        the Website.
                    </p>

                    <h4>Use of Information</h4>
                    <p>Information is used for the following purposes:</p>
                    <ul>
                        <li>
                            Registration and Profile Information: To gain full
                            access to our Website and Service as a user, you
                            must register for an IntellyQ account. When you
                            register for an account, we need your personal
                            information, including the following: (i) your name,
                            email address, and account password; (ii) Payment
                            information to complete your transactions; (iii)
                            Your IP addresses, devices, and locations used to
                            access IntellyQ. When you register, we will also
                            seek your consent to retrieve additional personal
                            information about you from third parties and other
                            identification/verification services. We may also
                            collect additional personal information in other
                            ways, including through emails, surveys, and other
                            forms of communication. Once you begin using the
                            Service through your IntellyQ account, we will keep
                            records of your transactions and collect information
                            about your other activities related to our Service.
                        </li>
                        <li>
                            Social Sharing: If you choose to use any of the
                            social sharing features supported by the Website to
                            share information with your network, such
                            information will be processed according to the
                            privacy practices of the applicable third-party
                            social media provider as well as your privacy
                            settings in respect of your account with such
                            third-party social media provider.
                        </li>
                        <li>
                            Surveys and Questionnaires: If you choose to
                            complete a survey, we will use your survey responses
                            and biographical information from your profile, such
                            as your age, gender, and educational background, to
                            create anonymous aggregate statistics.
                        </li>
                        <li>
                            Transactional Notifications: We provide
                            notifications for certain activities relating to
                            your use of our Services despite your indicated
                            email preferences. For example, we may send you
                            notices of any awards or scholarships that may be of
                            interest to you or let you know about updates to our
                            Terms of Use or Privacy Policy.
                        </li>
                        <li>
                            Marketing Communications: If you opt in to receive
                            marketing communications from us, we will keep you
                            up to date on our products and services. You may
                            withdraw your consent to receiving marketing
                            communications from us at any time by following the
                            opt-out instructions in each communication or by
                            contacting our marketing department at
                            hello@tranquiltech.io
                        </li>
                        <li>
                            Statistics: We also collect statistics about the use
                            of the Services. This information will be kept
                            confidential; however, aggregate statistics that do
                            not personally identify an individual will be kept
                            by us. Such aggregate statistics may be made
                            available to other members or third parties,
                            including advertisers and sponsors. We will also use
                            this information to tailor our content and improve
                            the Services.
                        </li>
                        <li>
                            System Logs and Cookies: Cookies are used by us to
                            track content usage and traffic on the Website. A
                            cookie is a feature of your web browser that
                            consists of a text file placed on your hard disk by
                            a web server. The Website uses cookies to help it
                            compile aggregate statistics about usage of this
                            Website, such as how many users visit the Website,
                            how long users spend viewing the Website, and what
                            pages are viewed most often. This information is
                            used to improve the content of the Website. You can
                            set your browser to notify you when you are sent a
                            cookie. This gives you the chance to decide whether
                            or not to accept it. If you disable cookies, you may
                            not be able to take advantage of all the features of
                            the Website. We do not link any of the information
                            we use in cookies to any personally identifiable
                            information submitted by you when you are on the
                            Website.
                        </li>
                    </ul>

                    <p>
                        Your IP address is reported by your web browser whenever
                        you visit a page on the Website. This information is
                        recorded together with your registration information in
                        our databases. If we plan to use your Personal
                        Information in the future for any other purposes not
                        identified above, we will only do so after informing you
                        by updating this Privacy Policy. See further the section
                        of this Privacy Policy entitled ‘Amendment of this
                        Policy’.
                    </p>

                    <h4>Disclosures and Transfers</h4>
                    <p>
                        We have put in place contractual and other
                        organizational safeguards with our employees to ensure a
                        proper level of protection of your Personal Information.
                        In addition to those measures, we will not disclose or
                        transfer your Personal Information to third parties
                        without your permission, except as specified in this
                        Privacy Policy (see further Important Exceptions below).
                    </p>

                    <p>
                        As of the date of this Privacy Policy, we share Personal
                        Information about you in respect of the Website only
                        with our service providers, which include our website
                        host, cloud service provider, analytics providers, email
                        marketing provider, and payment processors. Our service
                        providers host data in the United Arab Emirates and
                        Canada and accordingly your Personal Information may be
                        available to the UAE and Canadian government or agencies
                        under a lawful order, irrespective of the safeguards we
                        have put in place for the protection of your Personal
                        Information.
                    </p>

                    <h4>Proprietary Rights</h4>
                    <p>
                        From time to time, we may employ third parties to help
                        us improve the Website and/or the Services. These third
                        parties may have limited access to databases of user
                        information solely for the purpose of helping us to
                        improve the Website and/or the Services, and they will
                        be subject to contractual restrictions prohibiting them
                        from using the information about our members for any
                        other purpose.
                    </p>

                    <h4>Exceptions</h4>
                    <p>
                        We may disclose your Personal Information to third
                        parties without your consent if we have reason to
                        believe that disclosing this information is necessary to
                        identify, contact, or bring legal action against someone
                        who may be causing injury to or interference with
                        (either intentionally or unintentionally) our rights or
                        property, other users of the Services, or anyone else
                        (including the rights or property of anyone else) that
                        could be harmed by such activities. We may disclose
                        Personal Information when we believe in good faith that
                        such disclosure is required by and in accordance with
                        the law.
                    </p>
                    <p>
                        We may also disclose your Personal Information in
                        connection with a corporate re-organization, a merger or
                        amalgamation with another entity, a sale of all or a
                        substantial portion of our assets or stock, including
                        any due diligence exercise carried out in relation to
                        the same, provided that the information disclosed
                        continues to be used for the purposes permitted by this
                        Privacy Policy by the entity acquiring the information.
                    </p>

                    <h4>Security</h4>
                    <p>
                        The security of your Personal Information is important
                        to us. We use commercially reasonable efforts to store
                        and maintain your Personal Information in a secure
                        environment. We take technical, contractual,
                        administrative, and physical security steps designed to
                        protect Personal Information that you provide to us. We
                        have implemented procedures designed to limit the
                        dissemination of your Personal Information to only such
                        designated staff as are reasonably necessary to carry
                        out the stated purposes we have communicated to you.
                    </p>
                    <p>
                        In certain areas, such as when you provide credit card
                        or other payment information to us, the Website works to
                        help protect the security of your credit card and other
                        payment information during transmission by using
                        industry standard Secure Sockets Layer (SSL) encryption
                        technology.
                    </p>
                    <p>
                        You are also responsible for helping to protect the
                        security of your Personal Information. For instance,
                        never give out your email account information or your
                        password for the Services to third parties.
                    </p>

                    <h4>Retention</h4>
                    <p>
                        We will keep your Personal Information for as long as it
                        remains necessary for the identified purpose or as
                        required by law, which may extend beyond the termination
                        of our relationship with you. We may retain certain data
                        as necessary to prevent fraud or future abuse, or for
                        legitimate business purposes, such as analysis of
                        aggregated, non-personally-identifiable data, account
                        recovery, or if required by law. All retained personal
                        information will remain subject to the terms of this
                        Privacy Policy. If you request that your name be removed
                        from our databases, it may not be possible to completely
                        delete all your Personal Information due to
                        technological and legal constraints.
                    </p>

                    <h4>Policy Amendment</h4>
                    <p>
                        We reserve the right to change this Privacy Policy at
                        any time. If we decide to change this Privacy Policy in
                        the future, we will post an appropriate notice on the
                        home page. Any non-material change (such as
                        clarifications) to this Privacy Policy will become
                        effective on the date the change is posted, and any
                        material changes will become effective 30 days from
                        their posting on the Website. Unless stated otherwise,
                        our current Privacy Policy applies to all Personal
                        Information that we have about you. The date on which
                        the latest update was made is indicated at the bottom of
                        this document. We recommend that you print a copy of
                        this Privacy Policy for your reference and revisit this
                        policy from time to time to ensure you are aware of any
                        changes. Your continued use of the Website signifies
                        your acceptance of any changes.
                    </p>

                    <p>
                        Delete/Deactivate Procedure: Users may unsubscribe to
                        any of our Services at any time or delete or deactivate
                        their account in its entirety by selecting the
                        appropriate action in the Account Settings under My
                        Profile.
                    </p>

                    <h4>Access and Accuracy</h4>
                    <p>
                        You have the right to access the Personal Information we
                        hold about you to verify the Personal Information we
                        have collected in respect to you and to have a general
                        account of our uses of that information. Upon receipt of
                        your written request, we will provide you with a copy of
                        your Personal Information although in certain limited
                        circumstances, we may not be able to make all relevant
                        information available to you, such as where that
                        information also pertains to another user. In such
                        circumstances, we will provide reasons for the denial to
                        you upon request. We will endeavor to deal with all
                        requests for access and modifications in a timely
                        manner. We will make every reasonable effort to keep
                        your Personal Information accurate and up to date, and
                        we will provide you with mechanisms to update, correct,
                        delete, or add to your Personal Information as
                        appropriate. As appropriate, this amended Personal
                        Information will be transmitted to those parties to
                        which we are permitted to disclose your information.
                        Having accurate Personal Information about you enables
                        us to give you the best possible service.
                    </p>

                    <h4>Contact Us</h4>
                    <p>
                        You can help by keeping us informed of any changes, such
                        as a change of email address. If you would like to
                        access your information, if you have any questions,
                        comments, or suggestions, or if you find any errors in
                        our information about you, please contact us at:
                        <a href="mailto:hello@tranquiltech.io">
                            hello@tranquiltech.io
                        </a>
                        .
                    </p>
                </Container>
            </div>
        )
    }
}
