import * as React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'semantic-ui-react'

interface StateShape {}

export default class ContactUsColumn extends React.Component<StateShape> {
    render() {
        return (
            <Container className="ContactUsColumn">
                <h4 className="column-header">Company</h4>
                <div className="column-content">
                    <a href="mailto:hello@tranquiltech.io">Contact us</a>
                    <Link to="/terms-of-service">Terms of Use</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
            </Container>
        )
    }
}
