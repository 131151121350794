import * as React from 'react'
import { Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import './ToS.scss'

interface StateShape {}
interface PropsShape {}

export default class ToS extends React.Component<PropsShape, StateShape> {
    render() {
        return (
            <div className="ToS">
                <Link to="https://intellyq.com" className="intellyq-logo-link">
                    <img
                        className="intellyq-logo"
                        src="/logo-main.png"
                        alt="Intellyq logo"
                    />
                </Link>
                <Container className="content" text={true}>
                    <h1>Terms of Service</h1>
                    <div className="last-modified">
                        Last modified date: January 9, 2025
                    </div>
                    <p>
                        Please read this agreement carefully before accessing or
                        using this site. By accessing or using the site, you
                        agree to be bound by this agreement. IntellyQ is a
                        product of <strong>Tranquil Technologies FZ-LLC</strong>
                        , which owns and operates the website located at{' '}
                        <a href="https://www.intellyq.com">
                            https://www.intellyq.com
                        </a>{' '}
                        (“Web site”). Tranquil Technologies FZ-LLC and other
                        entities providing information and services on the site
                        ("Suppliers") provide the information and services on
                        this site to you, the user, conditional upon your
                        acceptance without modification of the terms,
                        conditions, and notices contained herein. These terms of
                        use apply to all users of the Web site, including users
                        who upload any materials to the Web site, users who use
                        services provided through this Web site, and users who
                        simply view the content on or available through this Web
                        site.
                    </p>

                    <p>
                        The information and services offered on this Web site
                        are provided with the understanding that neither
                        Tranquil Technologies FZ-LLC, IntellyQ, nor its
                        Suppliers or users are engaged in rendering legal or
                        other professional services or advice. Your use of the
                        site is subject to the additional disclaimers and
                        caveats that may appear throughout the site.
                    </p>

                    <p>
                        By using this Web site, you indicate your acceptance of
                        these Terms of Use. If you do not accept these Terms of
                        Use, then do not use this Web site or any of its content
                        or services. These Terms of Use may be amended or
                        updated by Tranquil Technologies FZ-LLC from time to
                        time without notice, and the Terms of Use may have
                        changed since your last visit to this Web site. It is
                        your responsibility to review these terms of use for any
                        changes. Your use after any amendments or updates of
                        these terms of use shall signify your assent to and
                        acceptance of such revised terms. Any new features that
                        may be added to this Web site from time to time will be
                        subject to these terms of use, unless stated otherwise.
                        You should visit this page periodically to review these
                        terms of use.
                    </p>

                    <h4>Personal and Limited Commercial Use Limitations</h4>
                    <p>
                        You may access, download and print materials on this
                        site for your personal and commercial use. You may not
                        modify, display, perform, publish, license, create
                        derivative works from, frame in another Web page, use on
                        any other Web site, transfer or sell any information,
                        software, lists of users, databases or other lists,
                        products or services obtained from this site. The
                        foregoing prohibition expressly includes, but is not
                        limited to, the practices of "screen scraping" or
                        "database scraping" to obtain lists of users or other
                        information. If and when requested by Tranquil
                        Technologies FZ-LLC, you agree to provide true, accurate
                        and complete user information and to refrain from
                        impersonating or falsely representing your affiliation
                        with any person or entity. Except with the written
                        permission of Tranquil Technologies FZ-LLC, you agree to
                        refrain from accessing or attempting to access password
                        protected, secure or non-public areas of this site.
                        Unauthorized individuals attempting to access prohibited
                        areas of this site may be subject to prosecution.
                    </p>

                    <h4>The Service</h4>
                    <p>
                        The IntellyQ service allows registered users ("Users")
                        to get and post information related to educational
                        material through the Web site (the “Service”). You can
                        view educational material without registering for an
                        account, however you must be a registered user to post a
                        contribution, or to access our extended service.
                    </p>

                    <h2>SUBSCRIPTION TERMS</h2>
                    <p>
                        Privacy. In order to use many of the features of the
                        Website, you will need to create an account with us. Our
                        collection and use of your registration information and
                        other personal information is governed by Tranquil
                        Technologies FZ-LLC's
                        <a href="/privacy-policy">Privacy Policy</a> setting out
                        the online information gathering and dissemination
                        practices with respect to this Website. Please review
                        the Privacy Policy, which is incorporated into these
                        Terms by reference.
                    </p>

                    <h4>Accounts and Subscriptions</h4>
                    <p>
                        <span className="bolded">Free Accounts.</span> Use of
                        this Website is also governed by Tranquil Technologies
                        FZ-LLC's Privacy Policy setting out the online
                        information gathering and dissemination practices with
                        respect to this Website. Please review the Privacy
                        Policy, which is incorporated into these Terms by
                        reference.
                    </p>

                    <p>
                        <span className="bolded">Subscription Accounts.</span>{' '}
                        Details of our subscription packages are available on
                        the Website. See further{' '}
                        <a href="/upgrade">intellyq.com/upgrade</a>. By
                        subscribing to an IntellyQ package, you are agreeing to
                        pay the fees in the amount and at the times indicated in
                        the sections on Billing in the Credits and Payment Terms
                        section of these Terms. Subscriptions to IntellyQ are
                        for personal use only and should not be shared with any
                        other individuals or entities. Tranquil Technologies
                        FZ-LLC reserves the right to suspend and revoke the
                        privileges to any account if any sharing of a personal
                        account is detected.
                    </p>

                    <p>
                        <span className="bolded">Closing Your Account.</span>{' '}
                        Users may close their account at any time by sending a
                        request to{' '}
                        <a href="mailto:hello@tranquiltech.io">
                            hello@tranquiltech.io
                        </a>
                        . Your account will be closed within 48 to 72 hours
                        after receiving your request. The closure of your
                        account will mean the termination of the User's access
                        to IntellyQ and its services, including the ability to
                        upload Content and profit from the distribution of any
                        Content provided and previously uploaded by the User.
                        Previously posted and uploaded Content will remain on
                        IntellyQ indefinitely unless Tranquil Technologies
                        FZ-LLC, in its sole discretion, decides to remove it.
                    </p>

                    <p>
                        <span className="bolded">
                            Subscription Cancellations.
                        </span>{' '}
                        You may cancel your IntellyQ subscription at any time,
                        and you will continue to have access to the IntellyQ
                        service that you subscribed for up to the end of your
                        billing period. WE DO NOT PROVIDE REFUNDS OR CREDITS FOR
                        ANY PARTIAL MEMBERSHIP PERIODS. To cancel, go to Billing
                        Settings under your account settings after you log in
                        and follow the instructions for cancellation.
                    </p>

                    <p>
                        Content stored on the Website is managed by Tranquil
                        Technologies FZ-LLC only for permitted temporary online
                        sharing purposes and not for permanent or long-term
                        storage or for temporary archival purposes. Tranquil
                        Technologies FZ-LLC will not, and is not obligated to,
                        maintain back-up copies of any and all Content or any
                        User's IntellyQ account information. Accordingly,
                        Tranquil Technologies FZ-LLC will bear no responsibility
                        or liability for any loss of Content or other
                        information stored or submitted to the Website.
                    </p>

                    <h2>CREDITS AND PAYMENT TERMS</h2>
                    <p>
                        <span className="bolded">Credits.</span> Users may
                        accumulate IntellyQ reward credits ("Credits") through
                        various prescribed activities on the Website. Users will
                        hold Credits subject to the provisions of these Terms as
                        well as all rules and policies posted on the Website
                        relating to Credits. Credits may be withheld or reduced
                        at any time in IntellyQ's absolute discretion. Without
                        limitation of the foregoing, IntellyQ may choose not to
                        award or honor Credits if a User is found to be in
                        violation of these Terms or other IntellyQ regulations
                        and policies. Credits are nonreturnable, nonrefundable,
                        nontransferable, and cannot be converted into monetary
                        currency or used to redeem any goods or services outside
                        of the Website. Users shall have no right to redeem
                        Credits for anything other than Content available for
                        purchase on the Website. Interest shall not accrue on
                        Credits. Transactions involving the accumulation or
                        expenditure of Credits may be subject to further terms
                        and conditions to be made available on the Website. Upon
                        termination of a User’s account by either IntellyQ or
                        the User, the User acknowledges that any and all Credits
                        remaining in that User's IntellyQ account will
                        automatically expire.
                    </p>

                    <h4>Billing</h4>
                    <p>
                        <span className="bolded">Recurring Billing.</span> By
                        starting your IntellyQ subscription, you authorize us to
                        charge you the applicable fee for your subscription at
                        the then-current rate, and any other charges you may
                        incur in connection with your use of the IntellyQ
                        service to your Payment Method. Current “Payment
                        Methods” are indicated on the Website. You acknowledge
                        that the amount billed for each billing period may vary
                        from month to month for reasons that may include
                        differing amounts due to promotional offers and/or
                        changing or adding a plan, and you authorize us to
                        charge your Payment Method for such varying amounts,
                        which may be billed monthly, quarterly, and yearly in
                        one or more charges depending on your subscription
                        package.
                    </p>

                    <p>
                        <span className="bolded">Price Changes.</span> We
                        reserve the right to adjust pricing for any Content or
                        services at any time as we may determine in our sole and
                        absolute discretion. Except as otherwise expressly
                        provided for in these Terms, any price changes will take
                        effect 30 days following email notice to you or an
                        update of pricing on the Website.
                    </p>

                    <p>
                        <span className="bolded">Billing Cycle.</span>{' '}
                        Subscription fees will be billed immediately upon
                        selecting your subscription and on the renewal date of
                        all subsequent membership billing periods (whether
                        monthly, quarterly, or annually) unless and until you
                        cancel your membership. We automatically bill your
                        Payment Method each month on the calendar day
                        corresponding to the commencement of your paying
                        membership. Membership fees are fully earned upon
                        payment. We reserve the right to change the timing of
                        our billing, in particular, as indicated below, if your
                        Payment Method has not successfully settled. Your
                        renewal date may change due to changes in your
                        Membership. Visit your Billing Settings once you are
                        logged in to your account to see the commencement date
                        for your next renewal period. We may authorize your
                        Payment Method in anticipation of membership or
                        service-related charges. As used in these Terms of Use,
                        "billing" shall indicate a charge, debit, or other
                        payment clearance, as applicable, against your Payment
                        Method.
                    </p>

                    <p>
                        <span className="bolded">No Refunds.</span> PAYMENTS ARE
                        NONREFUNDABLE AND THERE ARE NO CREDITS FOR PARTIALLY
                        USED PERIODS. Following any cancellation, however, you
                        will continue to have access to the service through the
                        end of your current billing period.
                    </p>

                    <h2>CONTENT TERMS</h2>
                    <h4>Content Warranties</h4>
                    <p>
                        If you provide Content, you represent and warrant that
                        you are the sole owner of all/any Content posted to
                        IntellyQ or have the owner's authority to provide such
                        Content to the Website, other Users, and Tranquil
                        Technologies FZ-LLC, and you possess all necessary
                        rights in said Content to make it available on the
                        Website and for use by other Users and Tranquil
                        Technologies, including, without limitation, copyright.
                        You also represent and warrant that the posting of
                        Content on or through IntellyQ and its services will not
                        breach intellectual property rights, privacy rights,
                        contract rights, copyrights, or any other rights of any
                        third party. You acknowledge and agree to pay for any
                        and all royalties, fees, and any other monies or
                        monetary obligations owing to any third party for any
                        Content provided and uploaded by you to IntellyQ. You
                        also represent and warrant that you will not use the
                        Website in breach of any applicable law or regulation,
                        including university or other academic regulations.
                    </p>

                    <h4>Content and Use</h4>
                    <p>
                        If you upload or wish to submit Content to the Website,
                        you thereby grant Tranquil Technologies FZ-LLC a
                        perpetual, irrevocable, nonexclusive, royalty-free,
                        worldwide right and license (with unrestricted right to
                        grant sublicenses) to post, distribute, display, copy,
                        reproduce, modify, alter, translate, publish,
                        distribute, download, transmit, and otherwise use such
                        Content in any format or medium for any purpose
                        whatsoever, including, but not limited to, commercially
                        profiting therefrom. Content uploaded will be visible to
                        other Users and may be accessed, displayed, downloaded,
                        printed, and otherwise used for personal, academic, or
                        educational purposes. Tranquil Technologies assumes no
                        liability for any material provided by Users, which may
                        contain personal information and/or inaccurate,
                        incomplete, inappropriate, and offensive material.
                        Tranquil Technologies does not review or evaluate the
                        accuracy of Content provided by Users.
                    </p>

                    <p>
                        Content stored on the Website is managed by Tranquil
                        Technologies FZ-LLC only for permitted temporary online
                        sharing purposes and not for permanent or long-term
                        storage or for temporary archival purposes. Tranquil
                        Technologies will not, and is not obligated to, maintain
                        backup copies of any and all Content or any User's
                        IntellyQ account information. Accordingly, Tranquil
                        Technologies will bear no responsibility or liability
                        for any loss of Content or other information stored or
                        submitted to the Website.
                    </p>

                    <h4>Prohibited Content and Activities</h4>
                    <p>
                        Tranquil Technologies reserves the right to restrict,
                        suspend, or terminate access to all or any part of
                        IntellyQ's services at any time, for any reason, with or
                        without prior notice and without any liability to you.
                        Use of the Website is subject to all applicable local,
                        provincial, and federal laws and regulations. Users are
                        solely responsible for all activities, acts, and
                        omissions that occur in, from, through, or under their
                        user names or passwords.
                    </p>

                    <p>Users shall not:</p>
                    <ul>
                        <li>
                            (a) copy or distribute any part of the Website
                            (including all of the contents of the Website)
                        </li>
                        <li>(b) alter or modify any part of the Website</li>
                        <li>
                            (c) upload, post, email, transmit, or otherwise make
                            available on the Website any material that is
                            unlawful, harmful, threatening, abusive, harassing,
                            tortuous, defamatory, vulgar, obscene, pornographic,
                            libelous, invasive of another's privacy, hateful, or
                            racially or ethnically objectionable, encourages
                            criminal behavior, gives rise to civil liability,
                            violates any law, or is otherwise objectionable, or
                            post a link to the Website from any third-party
                            website(s) containing such content
                        </li>
                        <li>
                            (d) upload, post, email, transmit, or otherwise make
                            available any falsehoods or misrepresentations or
                            create an impression that the User knows is
                            incorrect, misleading, or deceptive, or any material
                            that could damage or harm minors in any way
                        </li>
                        <li>
                            (e) impersonate any person or entity or misrepresent
                            their affiliation with a person or entity
                        </li>
                        <li>
                            (f) forge headers or otherwise manipulate
                            identifiers in order to disguise the origin of any
                            material transmitted to or through the Website or
                            impersonate another person or organization
                        </li>
                        <li>
                            (g) upload, post, email, transmit, or otherwise make
                            available any material that they do not have a right
                            to make available under any law or under a
                            contractual relationship
                        </li>
                        <li>
                            (h) upload, post, email, transmit, or otherwise make
                            available any material that infringes any patent,
                            trademark, trade secret, copyright, or other
                            proprietary rights of any party (including privacy
                            and publicity rights), or post a link to the Website
                            from any third-party website(s) containing such
                            content
                        </li>
                        <li>
                            (i) upload, post, email, transmit, or otherwise make
                            available any unsolicited or unauthorized
                            advertising, solicitations for business, promotional
                            materials, "junk mail," "spam," "chain letters,"
                            "pyramid schemes," or any other form of solicitation
                        </li>
                        <li>
                            (j) upload, post, email, transmit, or otherwise make
                            available any material that contains software
                            viruses or any other computer code, files, or
                            programs designed to interrupt, destroy, or limit
                            the functionality of any computer software or
                            hardware or telecommunications equipment or data or
                            the Website or that of any users or viewers of the
                            Website or that compromises a user's privacy
                        </li>
                        <li>
                            (k) interfere with or disrupt the Website or servers
                            or networks connected to the Website, or disobey any
                            requirements, procedures, policies, or regulations
                            of networks connected to the Website or probe, scan,
                            or test the vulnerability of any system or network
                            or breach or circumvent any security or
                            authentication measures
                        </li>
                        <li>
                            (l) intentionally or unintentionally violate any
                            applicable local, state, national, or international
                            law or regulation
                        </li>
                        <li>
                            (m) collect or store personal data about other users
                            or viewers
                        </li>
                        <li>
                            (n) resell the content of the Website, the use of
                            the Website, or access to the Website
                        </li>
                        <li>
                            (o) modify, translate, make derivative works of,
                            disassemble, decompile, reverse compile, or reverse
                            engineer any software provided as part of the
                            Website, except to the extent the foregoing
                            restrictions are expressly prohibited by applicable
                            law.
                        </li>
                    </ul>

                    <p>
                        Users also agree not to access the Website in a manner
                        that utilizes the resources of the Website more heavily
                        than would be the case for an individual person using a
                        conventional web browser.
                    </p>

                    <h4>Tranquil Technologies' Proprietary Rights</h4>
                    <p>
                        Except for (a) Content or (b) intellectual property
                        belonging to Users, all information, materials, and
                        content on the Website, including but not limited to
                        design, text, graphics, academic data, course materials,
                        documents, messages, ratings, reviews, other files, and
                        their selection and arrangement (collectively, "IntellyQ
                        Content") are the property of Tranquil Technologies
                        FZ-LLC and/or its licensors, with all rights reserved.
                        Users acknowledge and agree that the IntellyQ Content is
                        protected by intellectual property rights which may
                        include copyright, trademarks, service marks, patents,
                        or other proprietary rights and laws, including laws
                        covering data access and data compilations. No IntellyQ
                        Content may be modified, copied, distributed, framed,
                        reproduced, republished, downloaded, displayed,
                        incorporated into any derivative works or compilations,
                        posted, transmitted, rented, or sold in any form or by
                        any means, in whole or in part, without Tranquil
                        Technologies' prior written permission.
                    </p>

                    <h4>Communications with the Site</h4>
                    <p>
                        If you provide Tranquil Technologies with any
                        suggestions, comments, or other feedback relating to any
                        aspect of the Website and/or Service ("Feedback"),
                        Tranquil Technologies may use such Feedback in the
                        Website or in any other Tranquil Technologies products
                        or services (collectively, "Offerings").
                    </p>

                    <h4>Copyright Policy</h4>
                    <p>
                        Users agree that they will not abuse their usage of
                        IntellyQ and its services to reproduce, post,
                        distribute, sell or modify any copyrighted material,
                        trademarks, or intellectual property belonging to third
                        parties without obtaining prior written consent of the
                        owner for such rights. IntellyQ reserves the right in
                        its sole discretion to remove any and all Content posted
                        by any user for any reason as IntellyQ sees fit.
                        IntellyQ reserves the right and ability to terminate
                        membership privileges of any User who infringes upon the
                        copyright, trademarks, and intellectual property of
                        third parties upon notification to IntellyQ by the owner
                        of the rights.
                    </p>

                    <h4>Copyright Violation Claims</h4>
                    <p>
                        If a User wishes to make a claim of copyright
                        infringement, you must provide notification to IntellyQ
                        by sending us the following information via email to
                        using the following format:
                    </p>
                    <ol type="1">
                        <li>
                            Include a statement telling us that you have found
                            content on intellyq.com which you believe infringes
                            your copyright (for example, "I believe that the
                            content identified below infringes my copyright").
                        </li>
                        <li>
                            Tell us which country your copyright applies to.
                        </li>
                        <li>
                            Tell us the title of the content concerned and the
                            full URL for its page.
                        </li>
                        <li>
                            Explain to us in what way that content infringes
                            your copyright (e.g., the text is copied, the entire
                            content is a copy of an original work made by you,
                            etc.)
                        </li>
                        <li>
                            Identify the type and details (e.g., title,
                            publisher, dates, etc.) of the copyright work which
                            you own the rights to and which you believe has been
                            infringed. If this information is available on the
                            internet, it is helpful to send us a link.
                        </li>
                        <li>
                            Let us have contact information so that we can get
                            in touch with you (email address is preferred).
                        </li>
                        <li>
                            Let us have the contact information which we can
                            pass on to the submitter of the content concerned,
                            so that they can get in touch with you to resolve
                            your complaint directly (email address is
                            preferred).
                        </li>
                        <li>
                            Include the following statement: "I have a good
                            faith belief that use of the copyrighted work
                            described above is not authorized by the copyright
                            owner (or by a third party who is legally entitled
                            to do so on behalf of the copyright owner) and is
                            not otherwise permitted by law. I swear that the
                            information contained in this notification is
                            accurate and that I am the copyright owner or have
                            an exclusive right in law to bring infringement
                            proceedings with respect to its use."
                        </li>
                        <li>
                            Sign the notice. If you are providing notice by
                            e-mail, a scanned physical signature or a valid
                            electronic signature will be accepted.
                        </li>
                        <li>
                            Send the notice, in English, to the following
                            address: Attn: IntellyQ Copyright Infringement
                            Notification Email:{' '}
                            <a href="mailto:hello@tranquiltech.io">
                                hello@tranquiltech.io
                            </a>
                        </li>
                    </ol>

                    <p>
                        <span className="bolded">
                            Please also note that the information provided in
                            this legal notice may be forwarded to the person who
                            provided the allegedly infringing content. Please
                            also make sure you know whether the content that you
                            have seen on the Website infringes your copyright
                            because there may be adverse legal consequences in
                            your country if you make a false or bad faith
                            allegation of copyright infringement by using this
                            process.
                        </span>
                    </p>

                    <h2>OTHER IMPORTANT TERMS</h2>
                    <h4>Disclaimers</h4>
                    <p>
                        Tranquil Technologies FZ-LLC is not responsible for any
                        inaccuracies in Content posted or uploaded by Users or
                        of any links. As well, Tranquil Technologies does not
                        guarantee the accuracy of any Content provided and
                        uploaded by Tranquil Technologies. Tranquil Technologies
                        is not responsible for any errors or omissions or for
                        any consequences that may arise from the use of such
                        “flawed” information. Tranquil Technologies, IntellyQ
                        Content, and any Content made available through the site
                        are made available “as is” and “with all faults.” The
                        usage of IntellyQ, its services, and Content is entirely
                        at the individual risk of Users, and Tranquil
                        Technologies assumes no responsibility for third-party
                        advertisements that may be posted on IntellyQ nor does
                        it assume responsibility for any goods or services
                        advertised with its partners.
                    </p>

                    <p>
                        The online and offline conduct of any User is not the
                        responsibility of Tranquil Technologies. Tranquil
                        Technologies assumes no responsibility for errors,
                        omissions, interruptions, deletions, defects, delay in
                        operation or transmission, communications line failure,
                        theft or destruction or unauthorized access to, or
                        alteration of, any communication. Tranquil Technologies
                        cannot be held responsible for any problems or technical
                        malfunction including but not limited to computer
                        systems, servers, or internet providers, computer
                        equipment, software, or failure of email/email accounts
                        that may be due to technical problems or Internet
                        congestion problems. Tranquil Technologies will not be
                        responsible or liable for any harm to your computer
                        system, loss of data, or other harm that results from
                        your access to or use of the Website or any Content.
                        Under no circumstances shall Tranquil Technologies be
                        responsible for loss or damage arising from: (i) use of
                        IntellyQ and its services, (ii) any Content uploaded on
                        or through IntellyQ and its services (including, without
                        limitation, from any use of or reliance on any such
                        Content), or from the conduct of any Users of the
                        IntellyQ Services, whether online or offline. Services
                        provided by IntellyQ are provided “as is” and Tranquil
                        Technologies expressly disclaims the Website and its
                        services from any warranty or guarantee of fitness for
                        any purpose or non-infringement. Tranquil Technologies
                        cannot and does not guarantee and promise that the
                        Website and its services will always be available and
                        functional, nor can it guarantee or promise any specific
                        deterioration or improvement in academic results that
                        may arise from use of the Website and its services.
                    </p>

                    <h4>Links and Third-Party Websites</h4>
                    <p>
                        This Website (including User Content) may contain links
                        to other Websites that are not owned or controlled by
                        Tranquil Technologies FZ-LLC. In no event shall any
                        reference to any third party, third-party product, or
                        service be construed as an approval or endorsement by
                        Tranquil Technologies of that third party, third-party
                        product, or service. Tranquil Technologies is also not
                        responsible for the content of any linked Websites. Any
                        third-party Websites or services accessed from the
                        Website are subject to the terms and conditions of those
                        Websites and/or services, and you are responsible for
                        determining those terms and conditions and complying
                        with them. The presence on the Website of a link to any
                        other Website(s) does not imply that Tranquil
                        Technologies endorses or accepts any responsibility for
                        the content or use of such Websites, and you hereby
                        release Tranquil Technologies from all liability and/or
                        damages that may arise from your use of such Websites or
                        receipt of services from any such Websites.
                    </p>

                    <h4>Limitation on Liability</h4>
                    <p>
                        IN NO EVENT SHALL TRANQUIL TECHNOLOGIES FZ-LLC BE LIABLE
                        TO USERS OR THIRD PARTIES FOR ANY CONSEQUENTIAL,
                        EXEMPLARY, INDIRECT, INCIDENTAL, SPECIAL, OR PUNITIVE
                        DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFIT
                        DAMAGES THAT ARISE FROM THE USE OF INTELLYQ AND ITS
                        SERVICES, EVEN IF TRANQUIL TECHNOLOGIES HAS BEEN ADVISED
                        AND INFORMED OF THE POSSIBILITY OF THE OCCURRENCE OF
                        SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                        CONTAINED HEREIN, TRANQUIL TECHNOLOGIES' LIABILITY TO
                        USERS FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
                        FORM OF THE ACTION WILL AT ALL TIMES BE LIMITED TO THE
                        AMOUNT PAID, IF ANY, BY USERS TO TRANQUIL TECHNOLOGIES
                        FOR THE SERVICES UP TO THE EXACT DATE WHEN THE CAUSE OF
                        ACTION FIRST ARISES.
                    </p>

                    <h4>Disputes</h4>
                    <p>
                        The resolution of any dispute related to these Terms or
                        use of the Website or IntellyQ services shall be
                        governed by and construed in accordance with the laws of
                        the United Arab Emirates, without giving effect to any
                        principles of conflicts of law. Any legal action or
                        proceeding between Tranquil Technologies and Users shall
                        be brought exclusively in a court of competent
                        jurisdiction located in the UAE.
                    </p>

                    <h4>Indemnity</h4>
                    <p>
                        You agree to indemnify and hold Tranquil Technologies
                        and any of its employees and officers harmless from any
                        damage, cost, loss, liability, demand, or claim and
                        expense, including but not limited to reasonable
                        attorneys' fees, made by any third party due to or
                        arising out of your use of IntellyQ and its services in
                        violation of this Agreement and/or arising from a breach
                        of this Agreement and/or any breach of Users'
                        representations and/or warranties set forth above and/or
                        if any Content posted on IntellyQ or through its
                        services causes Tranquil Technologies to be liable to
                        another party.
                    </p>

                    <h4>Trademarks</h4>
                    <p>
                        All of the trademarks, logos, and service marks
                        (collectively referred to as the "Trademarks") displayed
                        on the Website are registered or unregistered trademarks
                        of Tranquil Technologies FZ-LLC or of third parties who
                        have authorized their use by Tranquil Technologies.
                        Nothing contained on the Website should be construed as
                        granting, by implication, estoppel, or otherwise, any
                        license or right to use any Trademark displayed on the
                        Website without the written permission of Tranquil
                        Technologies or the owner of the Trademarks. Your misuse
                        of the Trademarks or any other content displayed on the
                        Website, except as provided for in these Terms of Use,
                        is strictly prohibited and may be subject to
                        prosecution.
                    </p>

                    <h4>Availability and Updates</h4>
                    <p>
                        Tranquil Technologies may alter, suspend, or discontinue
                        this Website at any time and for any reason or no
                        reason, without notice. The Website and/or Service may
                        be unavailable from time to time due to maintenance or
                        malfunction of computer or network equipment or other
                        reasons. Tranquil Technologies may periodically add or
                        update the information and materials on this Website
                        without notice.
                    </p>

                    <h4>Collection of Information</h4>
                    <p>
                        This section outlines how personal information may be
                        collected and used (or not used) on this Website and
                        outlines responsibilities and liabilities that
                        correspond to such collection and/or use. These terms
                        cover this Website and its content exclusively and do
                        not include Websites that are linked, or malicious third
                        parties. It is our commitment to end users to operate
                        this Website with integrity and honesty—taking the time,
                        effort, and care to protect users and information that
                        may be provided.
                    </p>

                    <h4>Security</h4>
                    <p>
                        Information sent or received over the Internet is
                        generally insecure, and Tranquil Technologies cannot and
                        does not make any representation or warranty concerning
                        the security of any communication to or from the Website
                        or any representation or warranty regarding the
                        interception by third parties of personal or other
                        information. You are responsible for safeguarding the
                        password that you use to access the Service, and you are
                        responsible for any activities or actions under your
                        password. You agree to keep your password secure.
                        Tranquil Technologies will not be liable for any loss or
                        damage arising from your failure to comply with these
                        requirements.
                    </p>

                    <h4>Entire Agreement</h4>
                    <p>
                        These Terms, together with the Privacy Policy,
                        constitute the entire agreement between the parties
                        relating to the Website and all related activities.
                        These Terms shall not be modified except in writing
                        signed by both parties or by a new posting of these
                        Terms issued by Tranquil Technologies.
                    </p>

                    <h4>Severability</h4>
                    <p>
                        If any part of these Terms is deemed unlawful, void, or
                        unenforceable, that part shall be deemed severed and
                        shall not affect the validity and enforceability of the
                        remaining provisions.
                    </p>

                    <h4>Waiver</h4>
                    <p>
                        Failure of Tranquil Technologies to enforce any of the
                        provisions set forth in the Terms against Users or
                        others shall not be construed to be a waiver of the
                        right of Tranquil Technologies to enforce such
                        provisions.
                    </p>
                </Container>
            </div>
        )
    }
}
